import React from "react"

import Seo from "../components/seo"

const NotFoundPage = () => (
  <div>
    <Seo title="404 Not found" />
    <h1>404 Not Found</h1>
    <p>ページが見つかりません</p>
  </div>
)

export default NotFoundPage
